import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    constructor(
        private translocoService: TranslocoService,
        private snackBar: MatSnackBar,
    ) { }

    public openError(message: string, enableAction = true, duration = 5000) {
        return this.openSnackbar(message, enableAction, duration, 'snackbar-error');
    }

    public openInfo(message: string, enableAction = true, duration = 5000) {
        return this.openSnackbar(message, enableAction, duration, 'snackbar-info');
    }

    public openSuccess(message: string, enableAction = true, duration = 5000) {
        return this.openSnackbar(message, enableAction, duration, 'snackbar-success');
    }

    public openWarning(message: string, enableAction = true, duration = 5000) {
        return this.openSnackbar(message, enableAction, duration, 'snackbar-warning');
    }

    private openSnackbar(message: string, enableAction: boolean, duration: number, panelClass: string) {
        return this.snackBar.open(message, enableAction ? this.translocoService.translate('common.ok') : undefined, {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration,
            panelClass,
        });
    }
}
